const middleware = {}

middleware['guard'] = require('../middleware/guard.ts')
middleware['guard'] = middleware['guard'].default || middleware['guard']

middleware['redirects-middleware'] = require('../middleware/redirects-middleware.ts')
middleware['redirects-middleware'] = middleware['redirects-middleware'].default || middleware['redirects-middleware']

middleware['track-pageview'] = require('../middleware/track-pageview.ts')
middleware['track-pageview'] = middleware['track-pageview'].default || middleware['track-pageview']

export default middleware
